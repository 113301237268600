import React, { Component } from 'react';
import axios from 'axios';
import "../styles/ApplicationShowcase.scss";
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import {Helmet} from "react-helmet";
import { Event } from '../utils/tracking.js';
import Cookies from 'js-cookie';
import Gallery from "react-photo-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 

const _ = require('lodash')

export default class InternalPhotoGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            isLoading: false
        }
    };

    componentDidMount() {
        if (window.location.pathname === "/lp/2023ies_ottawa/internal-photo-gallery") {
            this.setState({
                isLoading: true,
            }) 
    
            axios.get(`https://res.cloudinary.com/eelighting/image/list/ies-ottawa-2023.json`)
            .then(res => {
                //console.log(res.data.resources);
                this.setState({cloudinaryImages: res.data.resources, isLoading: false});
            });
        }
    } 

    render(){
    const { isLoading, cloudinaryImages = [] } = this.state;

    cloudinaryImages.map((image, i) => { 
        image.original = `https://eelighting-res.cloudinary.com/image/upload/${image.public_id}.webp`;
        image.thumbnail = `https://eelighting-res.cloudinary.com/image/upload/${image.public_id}.webp`;
        image.id = i;
        image.src = `https://eelighting-res.cloudinary.com/image/upload/${image.public_id}.webp`;
        image.width = 1;
        image.height = 1;
        return image; 
    })

    let lightBoxImages =  cloudinaryImages.map((photo) => photo.src)
        return (
            <div className="main"> 
                <Helmet>                    
                    <title>2023 IES Ottawa Internal Photo Gallery | EELighting</title>
                </Helmet>
                <Breadcrumb className="breadcrumb">
                    <Breadcrumb.Item>
                        <Link 
                            to="/lp/2023ies_ottawa"
                            onClick={Event(`${window.location.pathname} page`, `2023IESOttawa clicked by ${Cookies.get('user_id') || "not logged in user"}`, `${window.location.pathname} page breadcrumb`)}
                        > 
                            2023 IES Ottawa
                        </Link>
                    </Breadcrumb.Item> 
                    <Breadcrumb.Item>Internal Photo Gallery</Breadcrumb.Item>
                </Breadcrumb>                
                <div className="gallery-container">
                    {!isLoading && (cloudinaryImages || []).length > 0 &&          
                        <Gallery 
                            photos={cloudinaryImages} 
                            direction={"column"} 
                            onClick={(e, { index }) => this.setState({ isOpen: true, photoIndex: index })}                            
                        />
                    }
                </div>
                {this.state.isOpen && 
                    <Lightbox                            
                        mainSrc={_.uniq(lightBoxImages)[this.state.photoIndex]}
                        nextSrc={_.uniq(lightBoxImages)[(this.state.photoIndex + 1) % _.uniq(lightBoxImages).length]}
                        prevSrc={_.uniq(lightBoxImages)[(this.state.photoIndex + _.uniq(lightBoxImages).length - 1) % _.uniq(lightBoxImages).length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + _.uniq(lightBoxImages).length - 1) % _.uniq(lightBoxImages).length,
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + 1) % _.uniq(lightBoxImages).length,
                        })
                        }
                    />
                }
                {!!isLoading &&
                <div className='loading-container'>
                    <LoadingOutlined /> Loading...
                </div>
                } 
            </div>
        );
    }
}