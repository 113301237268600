import React, { Component } from 'react';
import "../styles/ApplicationShowcase.scss";
import queryString from 'query-string';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import {Helmet} from "react-helmet";
import Gallery from "react-photo-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import Cookies from 'js-cookie';

const config = require('../config')
const _ = require('lodash')

export default class EachProductGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            isLoading: false,
            data: {                
                products_row: [],    
                product_images: [],           
                products_packaging_images: []
            }
        }
    };

    callAPI() {
        const urlParams = queryString.parse(this.props.location.search || '?')

        this.setState({
            isLoading: true,
        })
        return fetch(`${config.API_URL}/products-led/${urlParams.slug}`)
            .then(res => res.json())
            .then(res => {
                this.setState({ 
                    data: {
                        ...this.state.data,
                        ...res
                    },
                    isLoading: false,
                })
                return res
            })
            .catch(err => {
                console.log('This is error' + err )
                this.setState({ isLoading: false })
            })
    }   

    componentDidMount = () => {    
        this.callAPI();
    }

    render(){
    const { isLoading } = this.state;
    let { product_images = [], product_packaging_images = [], products_row = [] } = this.state.data;
    const urlParams = queryString.parse(this.props.location.search || '?')
    let product = products_row.find(product => product.slug === urlParams.slug)

    let lightBoxImages = [];
    let productSpecificImages = [];
    
    product_images.map(image => {
        image.src = image.url
        image.width = 1;
        image.height = 1;
        return image; 
    });

    product_packaging_images.map(image => {
        image.src = image.url
        image.width = 1;
        image.height = 1;
        return image; 
    });

    if (!!product && !urlParams.model) {
        productSpecificImages = product_images.concat(product_packaging_images);
        productSpecificImages.push({
            src: product.main_photo || "https://eelighting-res.cloudinary.com/image/upload/eelighting/creative-assets/eel-assets/Coming-Soon-Product-Image---R200701-0600.webp",
            url: product.main_photo || "https://eelighting-res.cloudinary.com/image/upload/eelighting/creative-assets/eel-assets/Coming-Soon-Product-Image---R200701-0600.webp"
        })
        productSpecificImages = _.uniqBy(productSpecificImages, "url");
        lightBoxImages =  productSpecificImages.map((photo) => photo.src)
    }

    let modelSpecificPhotos = [];
    let modelSpecificPackagingPhotos = [];

    if (!!product && !!urlParams.model && urlParams.model !== {}) {
        Object.entries(product.options).map(option => {
            if (option[0] === urlParams.model) {
                (option[1]["packaging photos"] || []).map(image => {
                    return modelSpecificPackagingPhotos.push({
                        src: image,
                        url: image,
                        width: 1,
                        height: 1
                    })
                });

                (option[1]["photos"] || []).map(image => {
                    return modelSpecificPhotos.push({
                        src: image,
                        url: image,
                        width: 1,
                        height: 1
                    })
                });

                productSpecificImages = modelSpecificPackagingPhotos.concat(modelSpecificPhotos);
                productSpecificImages.push({
                    src: option[1].main_photo || product.main_photo || "https://eelighting-res.cloudinary.com/image/upload/eelighting/creative-assets/eel-assets/Coming-Soon-Product-Image---R200701-0600.webp",
                    url: option[1].main_photo || product.main_photo || "https://eelighting-res.cloudinary.com/image/upload/eelighting/creative-assets/eel-assets/Coming-Soon-Product-Image---R200701-0600.webp"
                })
                
                productSpecificImages = _.uniqBy(productSpecificImages, "url");
                lightBoxImages =  productSpecificImages.map((photo) => photo.src)
            }
            return null;
        })
    }

    const isLoggedIn = !!Cookies.get('user_id')

    lightBoxImages =  productSpecificImages.map((photo) => photo.src)
    lightBoxImages = lightBoxImages.map(photo => {
        if (!isLoggedIn) {
            photo = (photo).toString().split("/")
            photo.splice(5, 0, 'q_auto,l_Watermark-01,w_100,x_20,y_20,o_3,a_315,fl_relative.tiled')
            photo = photo.filter((a, i, arr) => arr.indexOf(a) === i);
            photo = photo.join('/');
        }
        return photo;
    })
        return (
            <div className="main"> 
                <Helmet>
                    <meta name="description" content={(product || {}).description}/>
                    <meta name="keywords" content={(product || {}).description} />
                    <title>Product Gallery | EELighting</title>
                    <link rel="canonical" href={`https://www.eelighting.ca/product-gallery?slug=${(product || {}).slug}`} />
                </Helmet>
                <Breadcrumb className="breadcrumb">
                    <Breadcrumb.Item>
                        <Link 
                            to={!urlParams.model ? `/products-led?slug=${urlParams.slug}` : `/products-led?slug=${urlParams.slug}&model=${urlParams.model}`}
                        > 
                            Back to Product page 
                        </Link>
                    </Breadcrumb.Item> 
                    <Breadcrumb.Item>Product Gallery</Breadcrumb.Item>
                </Breadcrumb>
                <div className='back-to-product-page-link'> 
                    <Link
                        to={!urlParams.model ? `/products-led?slug=${urlParams.slug}` : `/products-led?slug=${urlParams.slug}&model=${urlParams.model}`}
                    >
                        Back to Product page 
                    </Link> 
                </div> 
                <div className="gallery-container">
                    {!isLoading && (productSpecificImages || []).length > 0 &&          
                        <Gallery 
                            photos={productSpecificImages} 
                            direction={"row"} 
                            onClick={(e, { index }) => this.setState({ isOpen: true, photoIndex: index })}
                        />
                    }
                </div>
                {this.state.isOpen && 
                    <Lightbox                            
                        mainSrc={_.uniq(lightBoxImages)[this.state.photoIndex]}
                        nextSrc={_.uniq(lightBoxImages)[(this.state.photoIndex + 1) % _.uniq(lightBoxImages).length]}
                        prevSrc={_.uniq(lightBoxImages)[(this.state.photoIndex + _.uniq(lightBoxImages).length - 1) % _.uniq(lightBoxImages).length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + _.uniq(lightBoxImages).length - 1) % _.uniq(lightBoxImages).length,
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + 1) % _.uniq(lightBoxImages).length,
                        })
                        }
                    />
                }
                {!!isLoading &&
                <div className='loading-container'>
                    <LoadingOutlined /> Loading...
                </div>
                } 
            </div>
        );
    }
}