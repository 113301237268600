import React, { Component, Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { ReactComponent as ComparisonIcon } from "./utils/ComparisonIcon.svg";
import './App.scss';
import { BackTop, notification, Layout, Button, Modal } from 'antd';
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";
import { Event } from './utils/tracking.js';
import { createBrowserHistory } from 'history';
import { Redirect } from 'react-router-dom';
import $ from "jquery";
import Icon from '@ant-design/icons';
import ClearCache from 'react-clear-cache';
import Cookies from 'js-cookie';
import { IdleTimeOutModal } from './components/IdleTimeOutModal.jsx';
import InternalPhotoGallery from './containers/InternalPhotoGallery';
import EachProductGallery from './components/EachProductGallery';
import ProductCertificationListing from './components/ProductCertificationListing';
import Home from "./containers/Home.jsx";

const NotFound = React.lazy(() => import('./components/NotFound.jsx'));
const NavBar = React.lazy(() => import('./components/NavBar.jsx'));
const SiteFooter = React.lazy(() => import('./components/Footer.jsx'));
const Products = React.lazy(() => import('./containers/Products.jsx'));
const Comparison = React.lazy(() => import('./containers/Comparison.jsx'));
const ScrollBannerText = React.lazy(() => import('./containers/ScrollBannerText.jsx'));
const ContactUs = React.lazy(() => import('./containers/ContactUs.jsx'));
const Resources = React.lazy(() => import('./containers/Resources.jsx'));
const Specsheets = React.lazy(() => import('./containers/Specsheets.jsx'));
const DimmerCompatibilityList = React.lazy(() => import('./containers/DimmerCompatibilityList.jsx'));
const CustomerService = React.lazy(() => import('./containers/CustomerService.jsx'));
const Careers = React.lazy(() => import('./containers/Careers.jsx'));
const RMA = React.lazy(() => import('./containers/RMA.jsx'));
const RMAFr = React.lazy(() => import('./containers/RMAFr.jsx'));
const Subscription = React.lazy(() => import('./containers/Subscription.jsx'));
const WhereToBuy = React.lazy(() => import('./containers/WhereToBuy.jsx'));
const AboutUs = React.lazy(() => import('./containers/AboutUs.jsx'));
const Login = React.lazy(() => import('./containers/Login.jsx'));
const Profile = React.lazy(() => import('./containers/Profile.jsx'));
const OurNews = React.lazy(() => import('./containers/OurNews.jsx'));
const FixtureSensor = React.lazy(() => import('./components/Fixture&Sensor.jsx'));
const Feedback = React.lazy(() => import('./containers/Feedback.jsx'));
const SearchResults = React.lazy(() => import('./containers/SearchResults.jsx'));
const SiteMap = React.lazy(() => import('./containers/SiteMap.jsx'));
const AvailabilityStatus = React.lazy(() => import('./components/AvailabilityStatus.jsx'));
const CCTLp = React.lazy(() => import('./components/CCTLp.jsx'));
const RedirectToNotFound = React.lazy(() => import('./components/RedirectToNotFound.jsx'));
const SPA = React.lazy(() => import('./containers/SPA.jsx'));
const MediaUpload = React.lazy(() => import('./containers/MediaUpload.jsx'));
const PartnersMediaUpload = React.lazy(() => import('./containers/PartnersMediaUpload.jsx'));
const ExpiredPromotion = React.lazy(() => import('./components/ExpiredPromotion.jsx'));
const BusinessUpdate = React.lazy(() => import('./containers/BusinessUpdate.jsx'));
const PromoSubmission = React.lazy(() => import('./containers/PromoSubmission.jsx'));
const QuoteMeListing = React.lazy(() => import('./containers/QuoteMeListing.jsx'));
const ApplicationShowcase = React.lazy(() => import('./containers/ApplicationShowcase.jsx'));
const SSTemplate = React.lazy(() => import('./containers/SSTemplate.jsx'));
const SPABallast = React.lazy(() => import('./containers/SPABallast.jsx'));
const CatalogueTemplate = React.lazy(() => import('./containers/CatalogueTemplate.jsx'));
const ProductsUrlBuilder = React.lazy(() => import('./containers/ProductsUrlBuilder.jsx'));
const FileNameGenerator = React.lazy(() => import('./containers/FileNameGenerator.jsx'));
const OrderTracking = React.lazy(() => import('./containers/OrderTracking.jsx'));
const OrderTrackingPublic = React.lazy(() => import('./containers/OrderTrackingPublic.jsx'));
const WebsiteChangeLog = React.lazy(() => import('./containers/WebsiteChangeLog.jsx'));
const ProductPriceListTable = React.lazy(() => import('./containers/ProductPriceListTable.jsx'));
const DBTables = React.lazy(() => import('./containers/DBTables.jsx'));
const WebsiteChangeLogSubmissionForm = React.lazy(() => import('./containers/WebsiteChangeLogSubmissionForm.jsx'));
const WebsiteChangeLogQueuedSection = React.lazy(() => import('./containers/WebsiteChangeLogQueuedSection.jsx'));
const ProductsStockTable = React.lazy(() => import('./containers/ProductsStockTable.jsx'));
const ChristmasLuncheon = React.lazy(() => import('./containers/ChristmasLuncheon.jsx'));
const ResetPassword = React.lazy(() => import('./containers/ResetPassword.jsx'));
const ForgotPassword = React.lazy(() => import('./containers/ForgotPassword.jsx'));
const ProductsStockAndPriceTable = React.lazy(() => import('./containers/ProductsStockAndPriceTable.jsx'));
const OEMMediaUpload = React.lazy(() => import('./containers/OEMMediaUpload.jsx'));
const InternalUseGallery = React.lazy(() => import('./containers/InternalUseGallery.jsx'));
const DriversSpecSheets = React.lazy(() => import('./containers/DriversSpecSheets.jsx'));
const PartnersLogin = React.lazy(() => import('./containers/PartnersLogin.jsx'));
const PartnersProfile = React.lazy(() => import('./containers/PartnersProfile.jsx'));
const BrowseBySeries = React.lazy(() => import('./containers/BrowseBySeries.jsx'));
const TechnicalAudit = React.lazy(() => import('./containers/TechnicalAudit.jsx'));
const PressReleases = React.lazy(() => import('./containers/PressReleases.jsx'));
const EachPressRelease = React.lazy(() => import('./components/EachPressRelease.jsx'));
const SampleOrder = React.lazy(() => import('./containers/SampleOrder.jsx'));
const BackOrderLegacyProductsTable = React.lazy(() => import('./containers/BackOrderLegacyProductsTable.jsx'));
const ProductETAReport = React.lazy(() => import('./components/ProductETAReport.jsx'));
const HistoricalDLCVersionsTable = React.lazy(() => import('./components/HistoricalDLCVersionsTable.jsx'));
const SPALog = React.lazy(() => import('./components/SPALog.jsx'));
const Projects = React.lazy(() => import('./containers/Projects.jsx'));
const ProjectsByType = React.lazy(() => import('./containers/ProjectsByType.jsx'));
const EachProject = React.lazy(() => import('./components/EachProject.jsx'));
const EachSPALog = React.lazy(() => import('./components/EachSPALog.jsx'));

const { Header, Footer, Content } = Layout;

const history = createBrowserHistory();
ReactGA.initialize('G-Z9F4X3QFLC');
const config = require('./config.js');

history.listen(location => {
    const { hash } = location;
    if (hash !== '') {
        // Push onto callback queue so it runs after the DOM is updated,
        // this is required when navigating from a different page so that
        // the element is rendered on the page before trying to getElementById.
        setTimeout(
            () => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            },
            0
        );
    }
});

function PrivateRoute({ component: Component, isLoggedIn, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

function PartnersPrivateRoute ({component: Component, isPartnerLoggedIn, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => isPartnerLoggedIn === true 
      ? <Component {...props} />
      : <Redirect to='/partners/login' />}
    />
  )
}

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      productCompareCount: 0,
      productsToCompare: {},
      listOfSuggestions: null,
      loggedIn: Cookies.get('user_id') ? true : false,
      partnerLoggedIn: Cookies.get('partner_id') ? true : false,
      redirecresetTimeoutt: false,
      partnersRedirect: false,
      showModal: false,
      comparisonUrl: window.location.search.includes("models") ? window.location.pathname + window.location.search : "/pcm",
      data: {
        pages_row: [],
        press_releases_row: []
      }
    };

    this.events = [
      'load', 
      'mousemove', 
      'mousedown',
      'click', 
      'scroll', 
      'keypress'
    ];
  }

  componentDidMount = () => {
    this.callAPI();

    if (window.location.pathname === "/search" && window.location.search === "?q=LAA&slug=laa&model=in%20dev") {
      window.location.href = "https://eelighting-res.cloudinary.com/image/upload/eelighting/installation-instructions/indoor-led/architectural-fixtures/laa/EELighting_LAA_Series_-_Installation_Instructions.pdf"
    }

    this.events.forEach((event) => {
      window.addEventListener(event, this.resetTimeout);
    });

    if (!!Cookies.get('user_id')) {
      this.setTimeout();
    }
    //this.formsNotAvailable();

    if (!this.state.loggedIn) {
      document.addEventListener('contextmenu', event => event.preventDefault());
    }
  }

  callAPI = () => {
    return fetch(`${config.API_URL}/pages`)
            .then((res) => res.json())
            .then((res) => {               
                this.setState({
                    data: {
                        ...this.state.data,
                        ...res,
                        pages_row: res.pages_row,
                        press_releases_row: res.press_releases_row
                    },
                });
                return res;
            })
            .catch((err) => {
                console.log('Error: ', err);
            });
  }

  handleClose = () => {
    this.setState({showModal: false})
    this.resetTimeout();
  }

  clearTimeout = () => {
    if (this.warnTimeout)
      clearTimeout(this.warnTimeout);

    if (this.logoutTimeout)
      clearTimeout(this.logoutTimeout);
  }

  resetTimeout = () => {
    this.clearTimeout();
    this.setTimeout();
  }

  warn = () => {
    this.setState({showModal: true})
  }

  handleLogout = () => {
    Cookies.remove('user_id');
    Cookies.remove('userType');
    Cookies.remove('userId');
    Cookies.remove('accountType');
    Cookies.remove('department');
    Cookies.remove('company_name');
    Cookies.remove('userSwitch');

    this.destroy();  // Cleanup

    window.location.href="/login";
  }

  setTimeout = () => {
    if (!!Cookies.get('user_id')) {
      this.warnTimeout = setTimeout(this.warn,  1000 * 3600 * 1); // (3600 sec = 1 hr)

      this.logoutTimeout = setTimeout(this.handleLogout, 1000 * 3780 * 1); // (3780 sec = 1 hr + 180 sec (3 min))
    }
  }

  destroy = () => {
    this.clearTimeout();

    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetTimeout);
    });
  }

  countDown = () => {
    let secondsToGo = 60;
    const modal = Modal.info({
      title: 'You have been idle!',
      content: `You will be logged out in ${secondsToGo} seconds.`,
      footer: (
        <>
          <Button 
            type="danger" 
            onClick={this.handleLogout}
        >
            Logout
        </Button>
        <Button 
            type="primary" 
            onClick={this.handleClose}
        >
            Stay
        </Button>
        </>
      )
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
      modal.update({
        content: `You will be logged out in ${secondsToGo} seconds.`,
      });
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      Cookies.remove('user_id');
      Cookies.remove('userType');
      Cookies.remove('userId');
      Cookies.remove('accountType');
      Cookies.remove('department');
      Cookies.remove('company_name');
      Cookies.remove('userSwitch');
      modal.destroy();
      window.location.href="/login";    
    }, secondsToGo * 1000);
  };
  
  changeState = () => {
    this.setState({ loggedIn: true, redirect: true });
  }

  partnersChangeState = () => {
    this.setState({ partnerLoggedIn: true, partnersRedirect: true });
  }

  handleSearch = (query) => {
    history.push(`/search?q=${query}`)
  } 

  addProductToCompare({product, selectedOptions}) {
    let productsToCompare = {};
    if (Array.isArray(selectedOptions)) {
      selectedOptions.forEach(selectedOption => {
        const key = `${product.id}-${selectedOption}`;
        productsToCompare[key] = {
          ...(this.state.productsToCompare || {}),
          product,
          selectedOption,
        };
      });
    } else {
      const key = `${product.id}-${selectedOptions}`;
      productsToCompare[key] = {
        ...(this.state.productsToCompare || {}),
        product,
        selectedOption: selectedOptions,
      };
    }

    this.setState(
      prevState => {
        const updatedProductsToCompare = {
          ...prevState.productsToCompare,
          ...productsToCompare,
        };
  
        return {
          productsToCompare: updatedProductsToCompare,
          productCompareCount: Object.keys(updatedProductsToCompare).length,
        };
      },
      () => {
        const { productsToCompare } = this.state;
        const selectedModels = Object.values(productsToCompare).map(item => item.selectedOption);
        const selectedModelsString = selectedModels.join(',');
        const comparisonUrl = `/pcm?models=${encodeURIComponent(selectedModelsString)}`;
        this.setState({
          comparisonUrl: comparisonUrl,
        });
      }
    );
    // this.setState(
    //   prevState => ({
    //     productsToCompare: {
    //       ...prevState.productsToCompare,
    //       ...productsToCompare,
    //     },
    //     productCompareCount: Object.keys(prevState.productsToCompare).length + Object.keys(productsToCompare).length
    //   }),
    //   () => {
    //     const { productsToCompare } = this.state;
    //     const selectedModels = Object.values(productsToCompare).map(item => item.selectedOption);
    //     const selectedModelsString = selectedModels.join(',');
    //     var comparisonUrl = `/pcm?models=${encodeURIComponent(selectedModelsString)}`;
    //     this.setState({
    //       comparisonUrl: comparisonUrl
    //     })
    //   }
    // )

    history.push(`${window.location.pathname}${window.location.search}`)  

    if (window.location.pathname !== '/pcm') {
      notification['success']({
        message: 'Added to comparison!',
        description: (
          <div className="notification-container"> 
            <div>The selected product was added to comparison.</div>
            <span>
              <Button  
                onClick={() => { 
                  history.push(this.state.comparisonUrl); 
                  $(".ant-notification-notice.ant-notification-notice-closable").hide();
                  Event("GO TO PCM button", `GO TO PCM button clicked by ${Cookies.get('user_id') || "not logged in user"}`, "GO TO PCM button")
                }}
              >
                <Icon component={ComparisonIcon} />
                  GO TO PCM
              </Button>
            </span>
          </div>
        ),
        //duration: 30000
      })
    }
  }

  formsNotAvailable() {
    notification['warning']({
      message: 'Website forms are temporarily unavailable.',
      description: (
        <div className="notification-container forms"> 
          <div>Please note forms on the website are temporarily unavailable. We apologize for the inconvenience and are working on a solution.</div>
        </div>
      ),
      duration: 60,
      placement: 'topRight',
      className: "form-message",
      top: 0
    })
  }

  removeItemFromCompare(key) {
    const productsToCompare = {
      ...this.state.productsToCompare,
    }

    delete productsToCompare[key]

    let models = Object.values(productsToCompare).map(item => item.selectedOption)
    const selectedModelsString = models.join(',');
    const comparisonUrl = `/pcm?models=${encodeURIComponent(selectedModelsString)}`;
    history.push(comparisonUrl)  
    //window.history.replaceState(null, '', comparisonUrl);

    this.setState({
      productsToCompare,
      productCompareCount: Object.keys(productsToCompare).length,
      comparisonUrl: comparisonUrl,
    });
  }

  selectProductFromSearch(productSlug) {
    history.push(`/products-led?slug=${productSlug}`)

    this.setState({
      selectedProductFromSearch: productSlug
    })
  }

  render() {
    const { pages_row = [], press_releases_row = [] } = this.state.data;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (!!this.state.loggedIn && window.location.pathname === '/partners/login') {        
      Cookies.remove('user_id');
      Cookies.remove('userType');
      Cookies.remove('userId');
      Cookies.remove('accountType');
      Cookies.remove('department');
      Cookies.remove('company_name');
      Cookies.remove('userSwitch');
      window.location.href="/login";
    }

    // if (!!Cookies.get('user_id')) {
    //   var d = new Date("September 12, 2022 17:25:00");
    //   var d1 = new Date();   
    //   var timelimit = (d.getTime() - d1.getTime());
    //   if (timelimit > 0)   {
    //     setTimeout(function(){
    //         Cookies.remove('user_id');
    //         Cookies.remove('userType');
    //         Cookies.remove('userId');
    //         Cookies.remove('accountType'); 
    //         Cookies.remove('department');  
    //         Cookies.remove('company_name');
    //         window.location.href = '/login';     
    //     },timelimit);
    //   }
    // }
  
    const DefaultRoutes = () => {
    return (        
      <div>      
        {(msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) ? (
          <div className="unsupported-browsers-container">
            <div className="page">
              <div className="modal">
                <h1 className="modal__title">
                  Whoops!
                </h1>
                <p className="modal__message">
                  It seems you're visiting us from a browser that is not supported by our website. Please use one of the recommended browsers below in order 
                  to experience the full functionality of our website.
                </p>
                  <div className="browsers">
                    <a 
                      aria-label="Chrome"
                      className="browser" 
                      href="https://www.google.com/chrome"
                      target='_blank' 
                      rel="noopener noreferrer"
                      onClick={()=> Event("Unsupported browser page", `Chrome link clicked by ${Cookies.get('user_id') || "not logged in user"}`, "Unsupported browser page Chrome link")}
                    >
                      <img className="browser__logo" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/101448/chrome.webp" alt="Google Chrome"/>
                      <div>Chrome</div>
                    </a>
                    <a 
                      aria-label="Firefox"
                      className="browser" 
                      href="https://www.mozilla.org/firefox"
                      target='_blank' 
                      rel="noopener noreferrer"
                      onClick={()=> Event("Unsupported browser page", `Firefox link clicked by ${Cookies.get('user_id') || "not logged in user"}`, "Unsupported browser page Firefox link")}
                    >
                      <img className="browser__logo" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/101448/firefox.webp" alt="Mozilla Firefox"/>
                      <div>Firefox</div>
                    </a>
                    <a 
                      aria-label="Safari (macOS)"
                      className="browser" 
                      href="https://www.apple.com/safari"
                      target='_blank' 
                      rel="noopener noreferrer"
                      onClick={()=> Event("Unsupported browser page", `Safari link clicked by ${Cookies.get('user_id') || "not logged in user"}`, "Unsupported browser page Safari link")}
                    >
                      <img className="browser__logo" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/101448/safari.webp" alt="Apple Safari"/>
                      <div>Safari</div>
                      <sup>(macOS)</sup>
                    </a>
                    <a 
                      aria-label="Edge (Windows)"
                      className="browser" 
                      href="https://www.microsoft.com/microsoft-edge"
                      target='_blank' 
                      rel="noopener noreferrer"
                      onClick={()=> Event("Unsupported browser page", `Edge link clicked by ${Cookies.get('user_id') || "not logged in user"}`, "Unsupported browser page Edge link")}
                    >
                      <img className="browser__logo" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/101448/edge.webp" alt="Microsoft Edge"/>
                      <div>Edge</div>
                      <sup>(Windows)</sup>
                    </a>
                  </div>
              </div>
            </div>
          </div>
        ) : (
        <div>
          <Helmet>
              <meta charSet="utf-8" />
              <meta name="description" content="Energy Efficient Lighting Ltd. is a licensed UL certified manufacturer of trusted LED solutions.Headquartered in Markham, Ontario, we are proud to be Canadian owned and operated. With over 30 years of experience in traditional lighting, ballasts, drivers, and LED technologies, we confidently serve our clients with unparalleled industry expertise. We serve the Canadian and American distribution channels and offer total solutions for commercial, industrial, and institutional projects. To be the most preferred manufacturer of lighting solutions, continuously providing value to our partners by serving with integrity and reliability. We serve our partners by offering total solutions for residential, commercial, industrial, and institutional sectors by providing quality products, timely delivery, and exceptional service."/>
              <meta name="keywords" content="Agent, Ajax, Aurora, America, American, ballasts, Barrie, Brampton, Brock, Burlington, Caledon, Canadian, Clarington, channels, commercial, contractor, contractors, CULUS, distribution, distributor, drivers, East Gwillimbury, EE Lighting, EEL, EELighting, Efficient, electric, electrical, electrical contractors, electrician, Energy, energy efficiency, Energy Efficient Lighting, energy saving, ETL, experience, expertise, fixture, flourescent, Georgina, government, Greater Toronto Area, GTA, Halton Hills, home, industrial, industry, institutional, King, LED, LED solutions, licensed, Lighting, luminaire, manufacturer, Markham, Milton, Mississauga, Montreal, Newmarket, Oakville, Ontario, Oshawa, Pickering, projects, Quebec, Resale, Richmond Hill, save on, save on energy, Scugog, Toronto, traditional lighting, UL certified, United States, United States of America, unparalleled, US, USA, Uxbridge, Vaughan, Whitby, Whitchurch-Stouffville, wholesale." />
              <meta http-equiv="Cache-Control" content="max-age: 31536000, no-cache, no-store, must-revalidate"/>
              <title>EELighting</title>
          </Helmet>
          <Suspense fallback={<div><Icon type="loading" /> Loading...</div>}>
          <ClearCache>
          {({ isLatestVersion, emptyCacheStorage }) => {
            if (!isLatestVersion) {
              emptyCacheStorage();
            }

          return (
            <div className="App">            
              <IdleTimeOutModal 
                  showModal={this.state.showModal} 
                  handleClose={this.handleClose}
                  handleLogout={this.handleLogout}
              />
              <Layout>
                <Header>
                  <ScrollBannerText />
                  <NavBar 
                    productCompareCount={this.state.productCompareCount} 
                    onSelectFromSearch={(productId) => this.selectProductFromSearch(productId)}
                    handleSearch={this.handleSearch}
                    comparisonUrl={this.state.comparisonUrl}
                  />
                </Header>
                <Content>
                {(pages_row || []).length > 0 &&
                  <Switch>        
                    {(pages_row || []).length > 0 && pages_row.map(page => (
                      <Route 
                        key={page.id}
                        exact path={page.url} 
                        component={(props) => (
                          page.redirect_to ? (
                            <Redirect to={page.redirect_to} />
                          ) : (
                            page.status === "inactive" && page.type === "promo" ? (
                              <Redirect to="/expired-promo" />
                            ) : (
                              <Products 
                                {...props} 
                                addProductToCompare={(product) => this.addProductToCompare(product)} 
                                selectedFromSearch={this.state.selectedProductFromSearch}
                                history={history}
                              />
                            )
                          )
                        )}
                      />                            
                    ))}            
                    <Route exact path="/lp/product-stage-availability" component={AvailabilityStatus} />
                    <Route exact path="/lp/cct" component={CCTLp} />
                    <Route exact path="/lp/business-update" component={BusinessUpdate} />
                    <Route exact path="/lp/pressreleases" component={PressReleases} />
                    {(press_releases_row || []).length > 0 && press_releases_row.map(release => (
                      <Route 
                        key={release.id}
                        exact path={release.url} 
                        component={() => (
                          release.redirect_to ? (
                            <Redirect to={release.redirect_to} />
                          ) : (
                              <EachPressRelease 
                                press_releases_row={press_releases_row}
                              />
                            )                          
                        )}
                      />                            
                    ))} 
                    <Route exact path="/lp/pressreleases/2022/douglas-bluetooth-solutions-now-featured-at-eelighting" component={EachPressRelease} />
                    <Route exact path="/lp/pressreleases/2023/product-launch-for-new-linear-architectural-series-attrax" component={EachPressRelease} />
                    <Route exact path="/lp/pressreleases/2023/eeLighting-has-joined-current-lighting-as-an-official-oem-partner" component={EachPressRelease} />
                    <Route 
                      exact path="/lp/quoteme-listing" 
                      component={(props) => 
                        <QuoteMeListing 
                          {...props} 
                          addProductToCompare={(product) => this.addProductToCompare(product)} 
                          selectedFromSearch={this.state.selectedProductFromSearch}
                        />}
                    />
                    <Route 
                      exact path="/lp/sample-order" 
                      component={(props) => 
                        <SampleOrder
                          {...props} 
                          addProductToCompare={(product) => this.addProductToCompare(product)} 
                          selectedFromSearch={this.state.selectedProductFromSearch}
                        />}
                    />
                    <Route 
                      exact path="/lp/2023ies_ottawa/internal-photo-gallery" 
                      component={InternalPhotoGallery}
                    />
                    <Route 
                      exact path="/lp/application-showcase" 
                      component={ApplicationShowcase}
                    />
                    <Route
                      exact path="/lp/historical-dlc-versions"
                      component={HistoricalDLCVersionsTable}
                    />
                    <Route
                      exact path="/resources/product-certification-listing"
                      component={ProductCertificationListing}
                    />
                    <Route
                      exact path='/lp/series'         
                      component={BrowseBySeries}
                    />   
                    <Route
                      exact path='/lp/tar'         
                      component={TechnicalAudit}
                    />                                                           
                    <Route exact path="/" component={Home} />
                    <Route 
                      exact path="/pcm" 
                      component={(props) => <Comparison  {...props}
                        productsToCompare={this.state.productsToCompare} 
                        addProductToCompare={(product) => this.addProductToCompare(product)}
                        handleItemDelete={(key) => this.removeItemFromCompare(key)}
                        comparisonUrl={this.state.comparisonUrl} 
                      />} 
                    />
                    <Route 
                      exact path="/search" 
                      component={(props) => 
                        <SearchResults 
                          {...props}
                          listOfFilteredSuggestions={this.state.listOfFilteredSuggestions}
                          addProductToCompare={(product) => this.addProductToCompare(product)}
                          selectedFromSearch={this.state.selectedProductFromSearch}
                        />
                      } 
                    />
                    <Route 
                      exact path="/products-led" 
                      component={(props) => 
                        <Products 
                          {...props} 
                          addProductToCompare={(product) => this.addProductToCompare(product)} 
                          selectedFromSearch={this.state.selectedProductFromSearch}
                        />} 
                    /> 
                    <Route 
                      exact path="/product-gallery" 
                      component={(props) => 
                        <EachProductGallery 
                          {...props} 
                          addProductToCompare={(product) => this.addProductToCompare(product)} 
                          selectedFromSearch={this.state.selectedProductFromSearch}
                        />} 
                    /> 
                    <Route 
                      exact path="/products-ballast" 
                      component={(props) => 
                        <Products 
                          {...props} 
                          addProductToCompare={(product) => this.addProductToCompare(product)} 
                          selectedFromSearch={this.state.selectedProductFromSearch}
                        />} 
                    />   
                    <Route 
                      exact path="/products-retired-ballast" 
                      component={(props) => 
                        <Products 
                          {...props} 
                          addProductToCompare={(product) => this.addProductToCompare(product)} 
                          selectedFromSearch={this.state.selectedProductFromSearch}
                        />} 
                    /> 
                    <Route 
                      exact path="/products-retired-led" 
                      component={(props) => 
                        <Products 
                          {...props} 
                          addProductToCompare={(product) => this.addProductToCompare(product)} 
                          selectedFromSearch={this.state.selectedProductFromSearch}
                        />} 
                    /> 
                    <Route 
                      exact path="/products-retired-controls" 
                      component={(props) => 
                        <Products 
                          {...props} 
                          addProductToCompare={(product) => this.addProductToCompare(product)} 
                          selectedFromSearch={this.state.selectedProductFromSearch}
                        />} 
                    /> 
                    <Route 
                      exact path="/products-controls" 
                      component={(props) => 
                        <Products 
                          {...props} 
                          addProductToCompare={(product) => this.addProductToCompare(product)} 
                          selectedFromSearch={this.state.selectedProductFromSearch}
                        />} 
                    />                
                    <Route exact path="/contact" component={ContactUs} />
                    <Route exact path="/resources" component={Resources} />
                    <Route 
                      exact path="/resources/specsheets-led" 
                      component={(props) => 
                        <Specsheets 
                          {...props} 
                          addProductToCompare={(product) => this.addProductToCompare(product)} 
                        />} 
                    />
                    <Route 
                      exact path="/resources/specsheets-controls" 
                      component={(props) => 
                        <Specsheets 
                          {...props} 
                          addProductToCompare={(product) => this.addProductToCompare(product)} 
                        />} 
                    />
                    <Route 
                      exact path="/resources/specsheets-ballast" 
                      component={(props) => 
                        <Specsheets 
                          {...props} 
                          addProductToCompare={(product) => this.addProductToCompare(product)} 
                        />} 
                    />                   
                    <PrivateRoute 
                      isLoggedIn={this.state.loggedIn} 
                      exact path='/spec-sheet-template-generator' 
                      component={SSTemplate} 
                    /> 
                    <PrivateRoute 
                      isLoggedIn={this.state.loggedIn} 
                      exact path='/catalogue-template-generator' 
                      component={CatalogueTemplate} 
                    /> 
                    <Route exact path='/resources/dimmer-compatibility' component={DimmerCompatibilityList} />
                    <Route exact path="/support" component={CustomerService} />
                    <Route exact path="/fr/retour" component={RMAFr} />
                    <Route exact path="/partners/media-upload" component={PartnersMediaUpload} />
                    <Route exact path="/partners/oem/media-upload" component={OEMMediaUpload} />
                    <Route exact path="/careers" component={Careers} />
                    <Route exact path="/returns" component={RMA} />
                    <Route exact path="/subscribe" component={Subscription} />
                    <Route exact path="/where-to-buy" component={WhereToBuy} />
                    <Route exact path="/about-us" component={AboutUs} />
                    <Route exact path="/projects" component={Projects} />
                    <Route exact path="/projects/:type" component={ProjectsByType} />   
                    <Route exact path="/projects/:type/:projectName" component={EachProject} />                        
                    <Route 
                      exact path="/login" 
                      component={(props) => 
                        <Login 
                          {...props}
                          changeState={this.changeState}
                          redirect={this.state.redirect}
                        />} 
                    />                   
                    <Route 
                      exact path="/partners/login" 
                      component={(props) => 
                        <PartnersLogin 
                          {...props}
                          partnersChangeState={this.partnersChangeState}
                          partnersRedirect={this.state.partnersRedirect}
                        />} 
                    /> 
                    <Route exact path="/forgot-password" component={ForgotPassword} />
                    <Route exact path="/reset/:token" component={ResetPassword} />    
                    <Route 
                      exact path='/lp/order-tracking' 
                      component={OrderTrackingPublic} 
                    />               
                    <PrivateRoute 
                      isLoggedIn={this.state.loggedIn} 
                      exact path='/portal/spa' 
                      component={SPA} 
                    />   
                    <PrivateRoute 
                      isLoggedIn={this.state.loggedIn} 
                      exact path='/portal/order-tracking' 
                      component={OrderTracking} 
                    />                       
                    <PrivateRoute 
                      isLoggedIn={this.state.loggedIn} 
                      exact path='/portal/spa-log/:spa_id' 
                      component={EachSPALog} 
                    />
                    <PrivateRoute 
                      isLoggedIn={this.state.loggedIn} 
                      exact path='/portal/spa-log' 
                      component={SPALog} 
                    />   
                    <PrivateRoute 
                      isLoggedIn={this.state.loggedIn} 
                      exact path='/portal/spa-ballast' 
                      component={SPABallast} 
                    />                    
                    <PrivateRoute 
                      isLoggedIn={this.state.loggedIn} 
                      exact path='/portal/media-upload' 
                      component={MediaUpload} 
                    />                      
                    <PrivateRoute 
                      isLoggedIn={this.state.loggedIn} 
                      exact path='/portal/promo-submission' 
                      component={PromoSubmission} 
                    />       
                    <PrivateRoute 
                      isLoggedIn={this.state.loggedIn} 
                      exact path='/portal/led-price-list' 
                      component={ProductPriceListTable} 
                    />  
                    <PrivateRoute 
                      isLoggedIn={this.state.loggedIn} 
                      exact path='/portal/back-order-legacy-list' 
                      component={BackOrderLegacyProductsTable} 
                    />                     
                    <PrivateRoute 
                      isLoggedIn={this.state.loggedIn} 
                      exact path='/portal/products-stock' 
                      component={ProductsStockTable} 
                    />    
                    <PrivateRoute 
                      isLoggedIn={this.state.loggedIn} 
                      exact path='/portal/PIP' 
                      component={ProductsStockAndPriceTable} 
                    />                           
                    <PrivateRoute 
                      isLoggedIn={this.state.loggedIn} 
                      exact path='/portal/db-tables' 
                      component={DBTables} 
                    />               
                    <PrivateRoute 
                      isLoggedIn={this.state.loggedIn} 
                      exact path='/portal' 
                      component={Profile} 
                    />   
                    <PartnersPrivateRoute 
                      isPartnerLoggedIn={this.state.partnerLoggedIn} 
                      exact path='/partners/portal' 
                      component={PartnersProfile} 
                    />  
                    <PrivateRoute
                      isLoggedIn={this.state.loggedIn} 
                      exact path="/lp/in-dev" 
                      component={(props) => 
                        <Products 
                          {...props} 
                          addProductToCompare={(product) => this.addProductToCompare(product)} 
                          selectedFromSearch={this.state.selectedProductFromSearch}
                        />}                   
                    /> 
                    <PrivateRoute 
                      isLoggedIn={this.state.loggedIn} 
                      exact path='/portal/url-builder' 
                      component={ProductsUrlBuilder} 
                    />    
                    <PrivateRoute 
                      isLoggedIn={this.state.loggedIn} 
                      exact path='/portal/file-name-generator' 
                      component={FileNameGenerator} 
                    />     
                    <PrivateRoute 
                      isLoggedIn={this.state.loggedIn} 
                      exact path='/portal/website-change-log' 
                      component={WebsiteChangeLog} 
                    /> 
                    <PrivateRoute
                      isLoggedIn={this.state.loggedIn} 
                      exact path='/portal/website-change-log/submission-request'         
                      component={WebsiteChangeLogSubmissionForm}
                    />    
                    <PrivateRoute
                      isLoggedIn={this.state.loggedIn} 
                      exact path='/portal/website-change-log/queued-section'         
                      component={WebsiteChangeLogQueuedSection}
                    />   
                    <PrivateRoute 
                      isLoggedIn={this.state.loggedIn} 
                      exact path="/lp/internal-use-gallery" 
                      component={InternalUseGallery}
                    /> 
                    {!!this.state.loggedIn && 
                      <PrivateRoute 
                        isLoggedIn={this.state.loggedIn} 
                        exact path="/lp/lower-price" 
                        component={(props) => 
                          <Products 
                            {...props} 
                            addProductToCompare={(product) => this.addProductToCompare(product)} 
                            selectedFromSearch={this.state.selectedProductFromSearch}
                          />}
                      />   
                    }
                    {!!this.state.partnerLoggedIn &&
                    <PartnersPrivateRoute 
                      isPartnerLoggedIn={this.state.partnerLoggedIn} 
                      exact path="/lp/lower-price" 
                      component={(props) => 
                        <Products 
                          {...props} 
                          addProductToCompare={(product) => this.addProductToCompare(product)} 
                          selectedFromSearch={this.state.selectedProductFromSearch}
                        />}
                    /> 
                    }                    
                    <PrivateRoute 
                      isLoggedIn={this.state.loggedIn} 
                      exact path="/portal/specsheets-drivers" 
                      component={DriversSpecSheets}
                    />     
                    <PrivateRoute 
                      isLoggedIn={this.state.loggedIn} 
                      exact path="/portal/product-eta" 
                      component={ProductETAReport}
                    />                                                     
                    <Route
                      exact path='/2021xmasluncheon'         
                      component={ChristmasLuncheon}
                    />       
                    <Route
                      exact path='/expired-promo'         
                      component={ExpiredPromotion}
                    />                       
                    <Route exact path="/about-us/news" component={OurNews} />
                    <Route exact path="/resources/control-compatibility" component={FixtureSensor} />
                    <Route exact path="/website-feedback" component={Feedback} />
                    <Route exact path="/site-map" component={SiteMap} />
                    <Route component={RedirectToNotFound} />
                    <Route path="*" component={RedirectToNotFound} />
                    <Route path="" component={RedirectToNotFound} />                                  
                  </Switch>
                }
                </Content>
                <Footer>
                  <BackTop />
                  <SiteFooter />
                </Footer>
              </Layout>
            </div>
            )
        }}
        </ClearCache>
        </Suspense>
        </div>
        )
        }
      </div>
    );
    }
  

    return (
      <Router history={history}>        
        <Switch>
          <Suspense fallback={<div><Icon type="loading" /> Loading...</div>}>
            <Route component={NotFound} path="/notfound" />
            <Route component={ExpiredPromotion} path="/expired-promo" />
            <Route component={DefaultRoutes} />
          </Suspense>
        </Switch>
      </Router>
    );          
  }
}