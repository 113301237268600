
import React from 'react';
import { Modal, Button } from 'antd';

export const IdleTimeOutModal = ({showModal, handleClose, handleLogout}) => {
    return (
        <Modal 
            className="auto-logout-notification-container"
            visible={showModal} 
            onOk={handleClose}
            onCancel={handleClose}
            footer={[
                <div className="logout-button-container">
                    <Button 
                        type="danger"
                        onClick={handleLogout}
                    >
                        Logout
                    </Button>
                    <Button 
                        type="primary"
                        onClick={handleClose}
                    >
                        I'm still here!
                    </Button>
                </div>
            ]}
        >    
            <>       
                <p id="title">A friendly hello! <span role="img" aria-label="smile">🙂</span></p>            
                <p id="description">For security, you will be logged out of your account due to inactivity in 3 minutes.</p>
            </>
        </Modal>
    )
}