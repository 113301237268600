import axios from 'axios';
const config = require('../config');

export async function generateSignature(callback, params_to_sign) {
    try {
        const response = await axios.post(`${config.API_URL}/portal/generate-signature`, params_to_sign);
        callback(response.data);
    } catch (error) {
        console.error('Error generating signature:', error);
    }
}